import okaidia from '@theme-ui/prism/presets/prism-okaidia.json'

// example theme file
const theme = {
  useCustomProperties: true,
  initialColorMode: "dark",
  breakpoints: [ "640px", "768px", "1024px", "1280px"],
  space: [0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80, 128, 256, 512],
  sizes: [0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80, 128, 256, 512],
  fontSizes: [12, 14, 16, 18, 20, 22, 24, 32, 40, 48, 64],
  colors: {
    modes: {
      dark: {
        text: "rgba(255, 255, 255, 0.9)",
        background: "#232B3B",
        backgroundLighten10: "#1A2232",
        backgroundLighten20: "#2C3648",
        primaryDarken: "#7A5EFF",
        primary: "#A085FF",
        primaryLighten10: "#9D82FF",
        primaryLighten50: "#B298FF",
        primaryLighten70: "#D2C8FF",
        secondary: "#85FFD0",
        muted: "rgb(149, 153, 195, 0.13)"
      },
      default: {
        text: "#1A2232",
        background: "#e8e2e2",
        backgroundLighten10: "#fff",
        backgroundLighten20: "rgba(170,185,198, 1)",
        primaryDarken: "#7A5EFF",
        primary: "#11084e",
        primaryLighten10: "#9D82FF",
        primaryLighten50: "#B298FF",
        primaryLighten70: "#D2C8FF",
        secondary: "#00773f",
        muted: "rgb(149, 153, 195, 0.13)"
      }
    },
  },
  fonts: {
    body: "source-han-serif, Lato, 'PT Serif',  system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace"
  },
  fontWeights: {
    body: 400,
    heading: 700,
    display: 900
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  textStyles: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading"
    },
    display: {
      variant: "textStyles.heading",
      fontSize: [
        5,
        6
      ],
      fontWeight: "display",
      letterSpacing: "-0.03em",
      mt: 3
    }
  },
  // header: {
  //   logo: {
  //     flexDirection: "column",
  //     justifyContent: "center",
  //     a: { textDecoration: "none" },
  //     container: {
  //       p: 3,
  //       display: ["flex", "none"],
  //       visibility: ["visible", "hidden"],
  //       width: "100%",
  //       justifyContent: "space-between",
  //       alignItems: "center",
  //     },
  //   },
  // },

  styles: {
    Container: {
      p: 3,
      maxWidth: 1024
    },
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      article: {
        padding: [0, 2],
        pb: [2, 14],
        pt: [5, 2],
      },
      a: {
        color: "primary",
        textDecoration: "none",        
        ":hover": {
          color: "primaryDarken",
          textDecoration:"underline",
          transition: "all .35s cubic-bezier(.13,.43,.54,1.82)",
        }
      },
    },
    h1: {
      variant: "textStyles.display"
    },
    h2: {
      variant: "textStyles.heading",
      fontSize: 5
    },
    h3: {
      variant: "textStyles.heading",
      fontSize: 4
    },
    h4: {
      variant: "textStyles.heading",
      fontSize: 3
    },
    h5: {
      variant: "textStyles.heading",
      fontSize: 2
    },
    h6: {
      variant: "textStyles.heading",
      fontSize: 1
    },
    a: {
      color: "primary",
      ":hover": {
        color: "secondary"
      }
    },
    p: {
      marginTop: 0,
      marginBottom: "1rem",
    },
    pre: {
      variant: "prism",
      fontFamily: "monospace",
      fontSize: 1,
      p: 3,
      color: "text",
      bg: "muted",
      overflow: "auto",
      code: {
        color: "inherit"
      }
    },
    code: {
      ...okaidia,
      fontFamily: "monospace",
      color: "secondary",
      fontSize: 1
    },
    blockquote: {
      margin: "1rem 0 1rem",
      padding: "10px 20px",
      borderLeft: "3px solid rgba(102,51,153,.7)",
      color: "primaryDarken",
      background: "rgba(102,51,153,.05)",
      p: {
        marginBottom: "0",
      }
    },
    inlineCode: {
      fontFamily: "monospace",
      color: "secondary",
      bg: "muted"
    },
    table: {
      width: "100%",
      my: 4,
      borderCollapse: "separate",
      borderSpacing: 0,
      "th,td": {
        textAlign: "left",
        py: "4px",
        pr: "4px",
        pl: 0,
        borderColor: "muted",
        borderBottomStyle: "solid"
      }
    },
    th: {
      verticalAlign: "bottom",
      borderBottomWidth: "2px"
    },
    td: {
      verticalAlign: "top",
      borderBottomWidth: "1px"
    },
    hr: {
      border: 0,
      borderBottom: "1px solid",
      borderColor: "muted"
    },
    img: {
      maxWidth: "100%"
    }
  },
  prism: {
    ".comment,.prolog,.doctype,.cdata,.punctuation,.operator,.entity,.url": {
      color: "gray"
    },
    ".comment": {
      fontStyle: "italic"
    },
    ".property,.tag,.boolean,.number,.constant,.symbol,.deleted,.function,.class-name,.regex,.important,.variable": {
      color: "purple"
    },
    ".atrule,.attr-value,.keyword": {
      color: "primary"
    },
    ".selector,.attr-name,.string,.char,.builtin,.inserted": {
      color: "secondary"
    }
  },
  buttons: {
    primary: {
      color: "background",
      bg: "primary",
      cursor: "pointer"
    },
    secondary: {
      color: "background",
      bg: "secondary",
      cursor: "pointer"
    },
    disabled: {
      color: "background",
      cursor: "not-allowed"
    }
  },
  links: {
    primary: {
      cursor: "pointer",
      color: "primary",
      ":hover": {
        color: "secondary"
      }
    },
    underline: {
      cursor: "pointer",
      color: "primary",
      borderBottom: "2px solid",
      ":hover": {
        color: "secondary"
      }
    },
    bold: {
      cursor: "pointer",
      fontWeight: "bold",
      ":hover": {
        color: "secondary"
      }
    }  
  }
}
export default theme